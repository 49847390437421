<ngx-skeleton-loader *ngIf="isLoading$ | async; else isLoaded" count="1" [theme]="{height: '200px', width: '100%'}"></ngx-skeleton-loader>
<ng-template #isLoaded>
  <div class="db-card header-highlight page-break-inside-avoid" *ngIf="property$ | async">
    <ng-container *libIsPlatformBrowser="''; else loader">
      <ng-container *ngTemplateOutlet="homeValue"></ng-container>
    </ng-container>
  </div>
</ng-template>

<!--  Home Value Block-->
<ng-template #homeValue>
  <div class="db-card-body" *ngIf="homeValueEstimate$ | async; let homeValueEstimate; else: loader">
    <ng-container *ngIf="agentSignature$ | async; let signature">
      <ng-container *ngIf="homeValueEstimate.adjustment; else noAdjustment">
        <ng-container [ngTemplateOutlet]="heading"></ng-container>
        <lib-avm-updated-by-agent-strip
          *ngIf="settings$ | async; let settings"
          [lastAdjustment]="homeValueEstimate.adjustment"
          [signature]="signature"
          [agencyName]="settings.name"
        >
          <ng-container *ngIf="moduleSetting.showCTA">
            <div class="line-break small"></div>
            <button
              class="link text-center w-100 fw-500 text-size-14 print-d-none"
              libDashboardTrackButtonClick
              (click)="openImproveEstimate()"
            >
              {{ moduleSetting.linkText }}
            </button>
          </ng-container>
        </lib-avm-updated-by-agent-strip>
      </ng-container>
    </ng-container>
    <ng-template #noAdjustment>
      <ng-container *ngIf="homeValueEstimate.median; else noHomeValue">
        <div class="flex-box-md-center-between flex-sm-wrap">
          <div class="mr-3 mb-mobile-16">
            <ng-container [ngTemplateOutlet]="heading"></ng-container>
            <p class="price-text fw-600">
              <span *ngIf="homeValueEstimate.median; else hyphen" class="actual-data">
                {{ homeValueEstimate.median | currency: "USD":"symbol":"1.0-0" }}
              </span>
              <span class="dummy-data">$XXX,XXX</span>
            </p>
          </div>
          <div>
            <p class="text-size-16 fw-600 flex-box-all-center text-color-light-2">
              Estimated sale price range
              <app-icon color="var(--color-primary)" class="ml-1 c-pointer print-d-none" [matTooltip]="moduleSetting.infoMessage"
                >info
              </app-icon>
            </p>
            <div class="graph-c">
              <lib-home-value-range-graph class="graph"></lib-home-value-range-graph>
              <p class="low text-size-16 fw-600">
                <span class="actual-data"> ${{ homeValueEstimate.low | numberShorthand }} </span>
                <span class="dummy-data">$XXXK</span>
              </p>
              <p class="high text-size-16 fw-600">
                <span class="actual-data"> ${{ homeValueEstimate.high | numberShorthand }} </span>
                <span class="dummy-data">$XXXK</span>
              </p>
            </div>
          </div>
        </div>

        <ng-container *ngIf="moduleSetting.showDisclaimer">
          <div class="box-c flex-box-center disclaimer-border bg-blue-00 p-2 mt-4">
            <lib-icon class="text-size-16 mr-2 text-color-blue mw-16">info-filled</lib-icon>
            <p class="text-size-14 fw-500">{{ moduleSetting.disclaimerMessage }}</p>
          </div>
        </ng-container>
      </ng-container>
      <button
        class="mt-4 link text-center w-100 fw-500 text-size-14 print-d-none"
        (click)="openImproveEstimate()"
        libDashboardTrackButtonClick
        *ngIf="moduleSetting.showCTA"
      >
        {{ moduleSetting.linkText }}
      </button>
    </ng-template>

    <ng-template #heading>
      <p class="text-size-20 fw-600 flex-box-center">
        Your Home Estimate
        <a class="c-pointer" (click)="scrollToBottom()">*</a>
        <ng-container
          *ngIf="
            homeValueEstimate.confidence === HomeEstimateConfidence.HIGH ||
            homeValueEstimate.confidence === HomeEstimateConfidence.VERY_HIGH
          "
        >
          <img src="/assets/images/svg/confidence-high.svg" alt="confidence" class="img-confidence" [libTooltip]="confidenceHigh" />
        </ng-container>
        <ng-container *ngIf="homeValueEstimate.confidence === HomeEstimateConfidence.MEDIUM">
          <img src="/assets/images/svg/confidence-medium.svg" alt="confidence" class="img-confidence" [libTooltip]="confidenceMedium" />
        </ng-container>
        <ng-container
          *ngIf="
            homeValueEstimate.confidence === HomeEstimateConfidence.LOW || homeValueEstimate.confidence === HomeEstimateConfidence.VERY_LOW
          "
        >
          <img src="/assets/images/svg/confidence-low.svg" alt="confidence" class="img-confidence" [libTooltip]="confidenceLow" />
        </ng-container>
      </p>
    </ng-template>
  </div>

  <!--      No Home Value-->
  <ng-template #noHomeValue>
    <div>
      <p class="text-size-20 fw-600">{{ moduleSetting.heading }}</p>
      <p class="text-size-14 fw-500 text-color-light-2 mt-1">{{ moduleSetting.subheading }}</p>
    </div>
  </ng-template>
</ng-template>

<ng-template #hyphen>-</ng-template>

<!--    Home value Loader-->
<ng-template #loader>
  <div class="db-card-body">
    <ngx-skeleton-loader count="1" [theme]="{height: '90px', width: '100%'}"></ngx-skeleton-loader>
  </div>
</ng-template>

<ng-template #confidenceHigh>
  High Confidence: Your home value estimate utilizes a comprehensive range of public data for accuracy. However, it doesn't factor in unique
  elements such as the specific condition of your home or any distinctive improvements made. For a more personalized evaluation, contact
  your real estate agent.
</ng-template>

<ng-template #confidenceMedium>
  Medium Confidence: Due to varying reliability of data sources or potential limitations in available data, this home value estimate carries
  a medium level of confidence. While it uses a comprehensive range of public data for accuracy, it doesn't account for unique elements like
  the specific condition of your home or any distinct modifications made. For a more personalized evaluation, we recommend contacting your
  real estate agent.
</ng-template>

<ng-template #confidenceLow>
  Low Confidence: The lack of extensive data or comparable sales in your area significantly influences this home value estimate, resulting
  in a low level of confidence. While it makes use of available public data, it doesn't include unique aspects such as the specific
  condition of your home or any distinctive improvements. We strongly advise contacting your real estate agent for a more personalized and
  accurate evaluation."
</ng-template>
