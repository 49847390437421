import {ChangelogTriggeredBy} from "../../deal";

export enum CommunicationChannel {
  EMAIL = "EMAIL",
  DIRECT_MAIL = "DIRECT_MAIL"
}

export type LastChannelChangeResponse = {
  [channel in CommunicationChannel]: {
    timestamp: string;
    triggeredBy: ChangelogTriggeredBy;
  };
};

export enum SubscribeConfirmationReason {
  EMAIL_CONSENT = "EMAIL_CONSENT",
  VERBAL_CONSENT = "VERBAL_CONSENT"
}
