import {Inject, Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {AGENT_API_SERVICE_BASE_URL} from "./agent-api.service";
import {AvailableLandingPageLayouts, LandingPageSettingsResponse} from "fello-model";

@Injectable({
  providedIn: "root"
})
export class LandingPagePublicApiService {
  baseUrl: string;

  constructor(private http: HttpClient, @Inject(AGENT_API_SERVICE_BASE_URL) private agentBaseUrl: string) {
    this.baseUrl = `${this.agentBaseUrl}/agent/public/lp`;
  }

  getLandingPagePublicSettings<LPLayoutType extends AvailableLandingPageLayouts>(
    landingPageId: string
  ): Observable<LandingPageSettingsResponse<LPLayoutType>> {
    return this.http.get<LandingPageSettingsResponse<LPLayoutType>>(`${this.baseUrl}/${landingPageId}/settings`);
  }

  getPurlPagePublicSettings(purlId: string, landingPageConfigId?: string): Observable<LandingPageSettingsResponse> {
    return this.http.get<LandingPageSettingsResponse>(`${this.baseUrl}/p/${purlId}/settings`, {
      params: landingPageConfigId ? ({landingPageConfigId} as unknown as HttpParams) : undefined
    });
  }
}
