<form>
  <div class="flex-box-between flex-wrap flex-box-gap-3">
    <div class="radio-group-panel grey">
      <label class="radio-panel">
        <input
          type="radio"
          name="mortgage-rate-type"
          [ngModel]="mortgageTrendRateType$ | async"
          [value]="MORTGAGE_RATE_TYPE.PURCHASE"
          (change)="updateMortgageTrendRateType(MORTGAGE_RATE_TYPE.PURCHASE)"
        />
        Purchase
      </label>
      <label class="radio-panel">
        <input
          type="radio"
          name="mortgage-rate-type"
          [ngModel]="mortgageTrendRateType$ | async"
          [value]="MORTGAGE_RATE_TYPE.REFINANCE"
          (change)="updateMortgageTrendRateType(MORTGAGE_RATE_TYPE.REFINANCE)"
        />
        Refinance
      </label>
    </div>
    <div class="ml-auto flex-box-center flex-box-gap-1 flex-wrap">
      <span class="text-color-plum text-size-12 fw-500">Credit Rating</span>
      <div class="radio-group-panel grey">
        <label class="radio-panel">
          <input
            type="radio"
            name="mortgage-credit-bucket"
            [ngModel]="mortgageTrendCreditBucket$ | async"
            [value]="CREDIT_SCORE_BUCKET.VERY_HIGH"
            (change)="updateMortgageTrendCreditBucket(CREDIT_SCORE_BUCKET.VERY_HIGH)"
          />
          740 or higher
        </label>
        <label class="radio-panel">
          <input
            type="radio"
            name="mortgage-credit-bucket"
            [ngModel]="mortgageTrendCreditBucket$ | async"
            [value]="CREDIT_SCORE_BUCKET.HIGH"
            (change)="updateMortgageTrendCreditBucket(CREDIT_SCORE_BUCKET.HIGH)"
          />
          739 - 680
        </label>
        <label class="radio-panel">
          <input
            type="radio"
            name="mortgage-credit-bucket"
            [ngModel]="mortgageTrendCreditBucket$ | async"
            [value]="CREDIT_SCORE_BUCKET.LOW"
            (change)="updateMortgageTrendCreditBucket(CREDIT_SCORE_BUCKET.LOW)"
          />
          679 or lower
        </label>
      </div>
    </div>
  </div>
</form>

<ng-container *ngIf="!isLoading; else mortgagePlotLoader">
  <ng-container *ngIf="hasGraphData(); else noData">
    <div class="flex-box mt-4 flex-box-gap-3 data-c">
      <div class="flex-box stats-c flex-box-gap-3 min-width-120">
        <div>
          <p
            class="fw-600 text-size-32 text-color-plum-5"
            *ngIf="currentRateForLoanProgram[MORTGAGE_LOAN_PROGRAM.FIXED_30_YEAR] > 0; else noStats"
          >
            {{ !(isDummyDashboard$ | async) ? currentRateForLoanProgram[MORTGAGE_LOAN_PROGRAM.FIXED_30_YEAR] : "X.XX"
            }}<span class="fw-600 text-size-20">%</span>
          </p>
          <p class="text-color-plum-4 fw-400 text-size-14">{{ LoanProgramLabel[MORTGAGE_LOAN_PROGRAM.FIXED_30_YEAR] }}</p>
        </div>
        <div>
          <p
            class="fw-600 text-size-32 text-color-plum-5"
            *ngIf="currentRateForLoanProgram[MORTGAGE_LOAN_PROGRAM.FIXED_15_YEAR] > 0; else noStats"
          >
            {{ !(isDummyDashboard$ | async) ? currentRateForLoanProgram[MORTGAGE_LOAN_PROGRAM.FIXED_15_YEAR] : "X.XX"
            }}<span class="fw-600 text-size-20">%</span>
          </p>
          <p class="text-color-plum-4 fw-400 text-size-14">{{ LoanProgramLabel[MORTGAGE_LOAN_PROGRAM.FIXED_15_YEAR] }}</p>
        </div>
        <div>
          <p
            class="fw-600 text-size-32 text-color-plum-5"
            *ngIf="currentRateForLoanProgram[MORTGAGE_LOAN_PROGRAM.ARM_5_YEAR] > 0; else noStats"
          >
            {{ !(isDummyDashboard$ | async) ? currentRateForLoanProgram[MORTGAGE_LOAN_PROGRAM.ARM_5_YEAR] : "X.XX"
            }}<span class="fw-600 text-size-20">%</span>
          </p>
          <p class="text-color-plum-4 fw-400 text-size-14">{{ LoanProgramLabel[MORTGAGE_LOAN_PROGRAM.ARM_5_YEAR] }}</p>
        </div>
      </div>
      <div class="flex-1 chart-wrapper">
        <div>
          <div class="legends-c flex-box-center-end flex-box-gap-2 ml-auto whitespace-nowrap flex-wrap">
            <div class="flex-box-center flex-box-gap-1 c-pointer" *ngFor="let his of mortgageData; let index = index">
              <span class="h-bar" [ngStyle]="{'background-color': colorScheme.domain[index]}"></span>
              <p class="text-size-12 fw-500">{{ his.name | enumToLabel: "MORTGAGE_LOAN_PROGRAM" }}</p>
            </div>
          </div>
          <div class="chart-c line-chart-defaults">
            <ngx-charts-line-chart
              [scheme]="colorScheme"
              [results]="mortgageData"
              [xAxis]="true"
              [yAxis]="true"
              [legend]="false"
              [yAxisTickFormatting]="(isDummyDashboard$ | async) ? formatYaxisDummyValue : formatYaxisValue"
              [xAxisTicks]="xAxisTicks"
              [xAxisTickFormatting]="formatXAxisValue"
              [autoScale]="true"
              [yScaleMin]="yAxisMinValue"
              [yScaleMax]="yAxisMaxValue"
              [showGridLines]="true"
              [tooltipDisabled]="false"
              class="d-block mt-3 w-100"
            >
              <ng-template #tooltipTemplate let-model="model">
                <p class="m-auto text-size-14 pt-1 pb-1 text-left">{{ model?.name }}</p>
                <p class="m-auto text-size-14 pt-1 text-left pb-1">
                  <ng-container> {{ model?.series | enumToLabel: "MORTGAGE_LOAN_PROGRAM" }} -</ng-container>
                  <span *ngIf="!(isDummyDashboard$ | async); else dummyValues"> {{ model?.value | formatNumber: 3:0 }}% </span>
                </p>
              </ng-template>
              <ng-template #seriesTooltipTemplate let-model="model">
                <p class="m-auto text-size-14 pt-1 pb-1 text-left">{{ model?.[0].name }}</p>
                <div class="pb-1">
                  <div class="m-auto pb-1" *ngFor="let m of model">
                    <p class="m-auto text-size-14 text-left">
                      <span class="dot mr-1 d-inline-block" [ngStyle]="{'background-color': m?.extra.color}"></span>
                      <ng-container> {{ m?.series | enumToLabel: "MORTGAGE_LOAN_PROGRAM" }} -</ng-container>
                      <span *ngIf="!(isDummyDashboard$ | async); else dummyValues"> {{ m?.value | formatNumber: 3:0 }}% </span>
                    </p>
                  </div>
                </div>
              </ng-template>
            </ngx-charts-line-chart>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #mortgagePlotLoader>
  <ngx-skeleton-loader class="mt-4" count="1" [theme]="{height: '279px', width: '100%', marginTop: '28px'}"></ngx-skeleton-loader>
</ng-template>

<ng-template #noStats>
  <p class="fw-600 text-size-32 text-color-plum-5">--</p>
</ng-template>

<ng-template #noData>
  <p class="text-size-16 fw-600 flex-box-all-center text-color-plum-3 h-300">No data available</p>
</ng-template>

<ng-template #dummyValues> X.XX%</ng-template>
