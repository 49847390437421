import {Component, Input, OnInit} from "@angular/core";
import {AbstractDashboardService} from "../../../services";
import {FormsModule} from "@angular/forms";
import {CreditScoreBucket, MORTGAGE_RATE_MODE, MortgageBulkDataItem, MortgageLoanProgram, MortgageRateType} from "fello-model";
import {AsyncPipe, NgForOf, NgIf, NgStyle} from "@angular/common";
import {DestroyableBase} from "../../../../../lib";
import {BehaviorSubject, combineLatest, switchMap} from "rxjs";
import {distinctUntilKeyChanged, takeUntil, tap} from "rxjs/operators";
import {Color, LineChartModule, ScaleType} from "@swimlane/ngx-charts";
import {flatMap, orderBy} from "lodash-es";
import moment from "moment";
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {EnumToLabelPipe, FormatNumberPipe, FormatPricePipe} from "../../../../fello-ui-utils";

const LoanProgramColors: Record<MortgageLoanProgram, string> = {
  [MortgageLoanProgram.ARM_5_YEAR]: "#3D93F5",
  [MortgageLoanProgram.FIXED_15_YEAR]: "#8D6AE7",
  [MortgageLoanProgram.FIXED_30_YEAR]: "#F02C00"
};

const LoanProgramLabel: Record<MortgageLoanProgram, string> = {
  [MortgageLoanProgram.ARM_5_YEAR]: "5-year ARM",
  [MortgageLoanProgram.FIXED_15_YEAR]: "15-year fixed",
  [MortgageLoanProgram.FIXED_30_YEAR]: "30-year fixed"
};

interface MortgageSeriesData {
  name: string;
  series: {
    value: number;
    name: string;
    extra: {
      color: string;
    };
  }[];
}

@Component({
  selector: "lib-mortgage-trend-graph",
  templateUrl: "./mortgage-trend-graph.component.html",
  styleUrls: ["./mortgage-trend-graph.component.scss"],
  imports: [
    FormsModule,
    AsyncPipe,
    NgxSkeletonLoaderModule,
    NgIf,
    NgForOf,
    LineChartModule,
    EnumToLabelPipe,
    FormatPricePipe,
    FormatNumberPipe,
    NgStyle
  ],
  standalone: true
})
export class MortgageTrendGraphComponent extends DestroyableBase implements OnInit {
  protected readonly MORTGAGE_RATE_TYPE = MortgageRateType;
  protected readonly CREDIT_SCORE_BUCKET = CreditScoreBucket;
  protected readonly MORTGAGE_LOAN_PROGRAM = MortgageLoanProgram;
  protected readonly LoanProgramLabel = LoanProgramLabel;

  @Input()
  mortgageRateMode?: MORTGAGE_RATE_MODE;

  isDummyDashboard$ = this.dashboardService.isDummyDashboard$;
  isLoading = false;
  colorScheme: Color = {
    name: "color",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: []
  };
  mortgageData: MortgageSeriesData[] = [];
  currentRateForLoanProgram: Record<MortgageLoanProgram, number> = {
    [MortgageLoanProgram.FIXED_30_YEAR]: 0,
    [MortgageLoanProgram.FIXED_15_YEAR]: 0,
    [MortgageLoanProgram.ARM_5_YEAR]: 0
  };
  xAxisTicks: string[] = [];
  yAxisMinValue: number;
  yAxisMaxValue: number;

  private mortgageTrendRateTypeSubject = new BehaviorSubject(MortgageRateType.PURCHASE);
  mortgageTrendRateType$ = this.mortgageTrendRateTypeSubject.asObservable();
  private mortgageTrendCreditBucketSubject = new BehaviorSubject(CreditScoreBucket.VERY_HIGH);
  mortgageTrendCreditBucket$ = this.mortgageTrendCreditBucketSubject.asObservable();
  loanProgramOrder = Object.values(MortgageLoanProgram);

  constructor(private dashboardService: AbstractDashboardService) {
    super();
  }

  ngOnInit(): void {
    combineLatest([
      this.mortgageTrendRateType$,
      this.mortgageTrendCreditBucket$,
      this.dashboardService.property$.pipe(distinctUntilKeyChanged("propertyId"))
    ])
      .pipe(
        takeUntil(this.isDestroyed),
        tap(() => {
          this.isLoading = true;
        }),
        switchMap(([mortgageTrendRateType, mortgageTrendCreditBucket]) =>
          this.dashboardService.getMortgageBulkData({
            creditScoreBucket: mortgageTrendCreditBucket,
            mortgageRateType: mortgageTrendRateType,
            mortgageRateMode: this.mortgageRateMode
          })
        )
      )
      .subscribe({
        next: mortgageBulkData => {
          this.mortgageData = [];
          this.colorScheme.domain = [];
          const sortedMortgageData: MortgageBulkDataItem[] = orderBy(
            mortgageBulkData.data,
            item => this.loanProgramOrder.indexOf(item.loanProgram),
            ["asc"]
          );

          for (const mortgageBulkDataItem of sortedMortgageData) {
            this.colorScheme.domain.push(LoanProgramColors[mortgageBulkDataItem.loanProgram]);
            this.currentRateForLoanProgram[mortgageBulkDataItem.loanProgram] = Number(mortgageBulkDataItem.currentRate.toFixed(2));
            mortgageBulkDataItem.periodicRates = orderBy(mortgageBulkDataItem.periodicRates, ["rateObservedAt"], ["desc"]);
            mortgageBulkDataItem.periodicRates = mortgageBulkDataItem.periodicRates.slice(0, 28);
            mortgageBulkDataItem.periodicRates = orderBy(mortgageBulkDataItem.periodicRates, ["rateObservedAt"], ["asc"]);
            this.mortgageData.push({
              name: mortgageBulkDataItem.loanProgram,
              series: mortgageBulkDataItem.periodicRates.map(periodicRate => ({
                value: periodicRate.rate,
                name: moment(periodicRate.rateObservedAt).utc().format("DD MMM YYYY"),
                extra: {
                  color: LoanProgramColors[mortgageBulkDataItem.loanProgram]
                }
              }))
            });
          }
          this.configureAppropriateXAxisTicks();
          this.configureMaxAndMinYAxisValues();
          this.isLoading = false;
        }
      });
  }

  configureAppropriateXAxisTicks() {
    this.xAxisTicks = [];

    if (this.mortgageData.length > 0) {
      const series = this.mortgageData[0].series;
      if (series.length === 0) {
        return;
      }
      const totalDates = series.length;
      const tickCount = 6;
      const offset = 2;
      const adjustedTotalDates = Math.max(totalDates - offset, 0);
      const step = adjustedTotalDates > 0 ? Math.floor(adjustedTotalDates / (tickCount - 1)) : 1;
      const indices = Array.from({length: tickCount}, (_, i) => Math.min(offset + i * step, totalDates - 1));
      this.xAxisTicks = indices.map(index => {
        const date = moment(series[index].name, "DD MMM YYYY").toDate();
        return moment(date).format("DD MMM YYYY");
      });
    }
  }

  configureMaxAndMinYAxisValues() {
    const flattenedValues = flatMap(this.mortgageData, mortgageBulkData => mortgageBulkData.series.map(dataPoint => dataPoint.value));
    const arrayMin = Math.min(...flattenedValues);
    const arrayMax = Math.max(...flattenedValues);
    this.yAxisMinValue = Math.round(arrayMin - 0.2 * arrayMin);
    this.yAxisMaxValue = Math.round(arrayMax + 0.1 * arrayMax);
  }

  formatYaxisValue(number: number) {
    return `${number}%`;
  }

  formatXAxisValue(date: string) {
    return moment(date, "DD MMM YYYY").format("MMM YYYY");
  }

  formatYaxisDummyValue(number: number) {
    return "X%";
  }

  updateMortgageTrendCreditBucket(creditBucket: CreditScoreBucket) {
    this.mortgageTrendCreditBucketSubject.next(creditBucket);
  }

  updateMortgageTrendRateType(rateType: MortgageRateType) {
    this.mortgageTrendRateTypeSubject.next(rateType);
  }

  hasGraphData(): boolean {
    return this.mortgageData.some(item => item.series && item.series.length > 0);
  }
}
