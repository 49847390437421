import {mixinDestroyable, mixinSpinnerAndToast} from "../../../../lib";
import {DashboardModuleDirective, DashboardModuleDirectiveInputs} from "../../directives";
import {DashboardModuleButtonAction, DashboardModuleButtonActionType, DashboardModuleButtonType, DashboardModuleType} from "fello-model";
import {Component, ElementRef, forwardRef, OnInit, ViewContainerRef} from "@angular/core";
import {NgClass, NgIf} from "@angular/common";
import {IconComponent} from "../../../fello-ui-utils";
import {TooltipDirective} from "../../../fello-ui-lib";
import {MortgageTrendGraphComponent} from "./mortgage-trend-graph/mortgage-trend-graph.component";
import {DashboardTrackButtonClickDirective} from "../../directives/dashboard-track-button-click.directive";
import {NgxSpinnerService} from "ngx-spinner";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AbstractDashboardService} from "../../services";
import {PopupLeadFormComponent} from "../dialogs";
import {MatDialog} from "@angular/material/dialog";

const MortgageTrendComponentBaseComponent = mixinDestroyable(
  mixinSpinnerAndToast(
    class extends DashboardModuleDirective<DashboardModuleType.MORTGAGE_TREND> {
      constructor(public spinnerService: NgxSpinnerService, public snackBar: MatSnackBar, elementRef: ElementRef) {
        super(elementRef);
      }
    }
  )
);

@Component({
  selector: "lib-mortgage-trend",
  standalone: true,
  templateUrl: "./mortgage-trend.component.html",
  styleUrls: ["./mortgage-trend.component.scss"],
  imports: [NgIf, IconComponent, TooltipDirective, MortgageTrendGraphComponent, DashboardTrackButtonClickDirective, NgClass],
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: DashboardModuleDirectiveInputs,
  providers: [
    {
      provide: DashboardModuleDirective,
      useExisting: forwardRef(() => MortgageTrendComponent)
    }
  ]
})
export class MortgageTrendComponent extends MortgageTrendComponentBaseComponent implements OnInit {
  protected readonly DashboardModuleButtonType = DashboardModuleButtonType;

  constructor(
    private dashboardService: AbstractDashboardService,
    private viewContainerRef: ViewContainerRef,
    private dialog: MatDialog,
    elementRef: ElementRef,
    spinnerService: NgxSpinnerService,
    snackBar: MatSnackBar
  ) {
    super(spinnerService, snackBar, elementRef);
  }

  performCTAAction(action: DashboardModuleButtonAction): void {
    if (action.actionType === DashboardModuleButtonActionType.POPUP_LEAD_FORM) {
      this.dialog.open(PopupLeadFormComponent, {
        maxWidth: 580,
        data: {
          action
        },
        viewContainerRef: this.viewContainerRef,
        panelClass: ["mobile-bottom-sheet"]
      });
    } else {
      this.withSpinner(this.dashboardService.performCTAAction(action)).subscribe();
    }
  }
}
