import {Component, ElementRef, forwardRef, ViewChild, ViewContainerRef} from "@angular/core";
import {CommonModule} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {AgentSignatureElement, DashboardModuleType, DataProviderType} from "fello-model";
import {ImproveHomeEstimateComponent} from "../dialogs";
import {mixinDestroyable} from "../../../../lib/mixins/";
import {DashboardModuleDirective, DashboardModuleDirectiveInputs} from "../../directives/dashboard-module.directive";
import {AbstractDashboardService, AbstractDashboardSettingsService, DashboardSettingsService} from "../../services";
import {AvmConfidenceStatusComponent, TooltipDirective} from "../../../fello-ui-lib";
import {EnumToLabelPipe, IconComponent, NumberShorthandPipe} from "../../../fello-ui-utils";
import {HomeValueGraphComponent} from "../home-value-trend-v2/home-value-graph/home-value-graph.component";
import {AvmUpdatedByAgentStripComponent} from "../avm-updated-by-agent-strip/avm-updated-by-agent-strip.component";
import {AgentBrandingUtils} from "../../../../lib/utils/AgentBrandingUtils";
import {DashboardTrackButtonClickDirective} from "../../directives/dashboard-track-button-click.directive";

@Component({
  selector: "lib-multi-avm",
  standalone: true,
  imports: [
    CommonModule,
    NgxSkeletonLoaderModule,
    TooltipDirective,
    NumberShorthandPipe,
    EnumToLabelPipe,
    IconComponent,
    HomeValueGraphComponent,
    AvmUpdatedByAgentStripComponent,
    AvmConfidenceStatusComponent,
    DashboardTrackButtonClickDirective
  ],
  templateUrl: "./multi-avm.component.html",
  styleUrls: ["./multi-avm.component.scss"],
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: DashboardModuleDirectiveInputs,
  providers: [
    DashboardSettingsService,
    {
      provide: DashboardModuleDirective,
      useExisting: forwardRef(() => MultiAvmComponent)
    }
  ]
})
export class MultiAvmComponent extends mixinDestroyable(class extends DashboardModuleDirective<DashboardModuleType.MULTI_AVM> {}) {
  isLoading$: Observable<boolean> = this.dashboardService.isPropertyLoading;
  property$ = this.dashboardService.property$;
  felloMultiAvmResponse$ = this.dashboardService.felloMultiAvmResponse$;
  protected readonly AgentSignatureElement = AgentSignatureElement;
  protected readonly AgentBrandingUtils = AgentBrandingUtils;

  felloHvEstimates$ = this.dashboardService.felloMultiAvmResponse$.pipe(
    map(response => {
      const filteredAvms = response.allAvms.filter(estimate => {
        const providerSelection = this.moduleSetting.avmSelections.find(entry => entry.provider === estimate.provider);
        return providerSelection?.enabled; // Filter by enabled providers
      });

      // Filter out estimates without median
      const filteredWithMedian = filteredAvms.filter(estimate => estimate.median !== undefined);

      // Sort filteredAvms based on provider order in moduleSetting.avmSelections
      return filteredWithMedian.sort((a, b) => {
        const providerAIndex = this.moduleSetting.avmSelections.findIndex(entry => entry.provider === a.provider);
        const providerBIndex = this.moduleSetting.avmSelections.findIndex(entry => entry.provider === b.provider);
        return providerAIndex - providerBIndex;
      });
    })
  );

  lastAdjustment$ = this.dashboardService.felloMultiAvmResponse$.pipe(map(response => response.adjustment));

  dataProviders$ = this.dashboardService.felloMultiAvmResponse$.pipe(
    map(() => {
      return this.moduleSetting.avmSelections.filter(selection => selection.enabled).map(selection => selection.provider);
    })
  );

  agentSignature$ = this.dashboardService.agentSignature$;
  settings$ = this.dashboardSettingsService.settings$;
  DataProviderType = DataProviderType;
  @ViewChild("homeValueGraph") homeValueGraph?: HomeValueGraphComponent;

  constructor(
    protected dashboardService: AbstractDashboardService,
    private dialog: MatDialog,
    private viewContainerRef: ViewContainerRef,
    private dashboardSettingsService: AbstractDashboardSettingsService,
    elementRef: ElementRef
  ) {
    super(elementRef);
  }

  openImproveEstimate(): void {
    this.dialog.open(ImproveHomeEstimateComponent, {
      viewContainerRef: this.viewContainerRef,
      panelClass: ["mobile-bottom-sheet"]
    });
  }
}
