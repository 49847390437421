import {AfterViewInit, Component, Inject, OnInit, PLATFORM_ID} from "@angular/core";
import {AbstractPublicLandingPageService} from "../../services";
import {DestroyableBase} from "../../../../lib";
import {take, takeUntil, tap} from "rxjs/operators";
import {LandingPageLayoutType, LandingPageSettingsResponse} from "fello-model";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: "lib-landing-page-journey-template",
  templateUrl: "./landing-page-journey-template.component.html",
  styleUrls: ["./landing-page-journey-template.component.scss"]
})
export class LandingPageJourneyTemplateComponent extends DestroyableBase implements OnInit, AfterViewInit {
  landingPageSetting?: LandingPageSettingsResponse<LandingPageLayoutType.JOURNEY>;
  hasTrackedView = false;

  get websiteURLValue(): string {
    return this.landingPageSetting?.website?.replace(/^(https?:\/\/)?(www\.)?/i, "").split("?")[0] ?? "";
  }

  constructor(
    private publicLandingPageService: AbstractPublicLandingPageService<LandingPageLayoutType.JOURNEY>,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    super();
  }

  ngOnInit(): void {
    this.publicLandingPageService.landingPagePublicSetting$
      .pipe(
        takeUntil(this.isDestroyed),
        tap(landingPageSetting => (this.landingPageSetting = landingPageSetting))
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId) && !this.hasTrackedView) {
      this.hasTrackedView = true;
      this.publicLandingPageService.trackView().pipe(take(1)).subscribe();
    }
  }
}
