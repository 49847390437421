import {AutomationTwoWayBranchComponentSettings} from "./settings/AutomationTwoWayBranchComponentSettings";
import {AutomationWaitComponentSettings} from "./settings/AutomationWaitComponentSettings";
import {AutomationPostcardComponentSettings} from "./settings/AutomationPostcardComponentSettings";
import {AutomationBaseComponentSettings} from "./AutomationBaseComponentSettings";
import {
  AutomationCallWebhookComponentSettings,
  AutomationChangeRecordStatusComponentSettings,
  AutomationEmailComponentSettings,
  AutomationMultiWayBranchComponentSettings,
  AutomationRotateContactOwnerComponentSettings,
  AutomationTagComponentSettings
} from "./settings";
import {AutomationLadkiDiagramMeta} from "./AutomationComponentLink";
import {AutomationManageCommPrefsComponentSettings} from "./settings/AutomationManageCommPrefsComponentSettings";

export enum AutomationComponentType {
  START = "START",
  TWO_WAY_BRANCH = "TWO_WAY_BRANCH",
  MULTI_WAY_BRANCH = "MULTI_WAY_BRANCH",
  POSTCARD = "POSTCARD",
  EMAIL = "EMAIL",
  CALL_WEBHOOK = "CALL_WEBHOOK",
  GOTO = "GOTO",
  LOG = "LOG",
  WAIT = "WAIT",
  END = "END",

  ADD_TAG = "ADD_TAG",
  REMOVE_TAG = "REMOVE_TAG",
  CHANGE_RECORD_STATUS = "CHANGE_RECORD_STATUS",
  ROTATE_CONTACT_OWNER = "ROTATE_CONTACT_OWNER",
  ARCHIVE_PROPERTY = "ARCHIVE_PROPERTY",
  MANAGE_COMM_PREFS = "MANAGE_COMM_PREFS",

  // UI only
  BRANCH_TAG = "BRANCH_TAG",
  ADD_COMP = "ADD_COMP"
}

export const ActionableComponentTypes: AutomationComponentType[] = [
  AutomationComponentType.EMAIL,
  AutomationComponentType.POSTCARD,
  AutomationComponentType.CALL_WEBHOOK
];
export const EditableComponentTypes: AutomationComponentType[] = [
  AutomationComponentType.CALL_WEBHOOK,
  AutomationComponentType.START,
  AutomationComponentType.WAIT,
  AutomationComponentType.TWO_WAY_BRANCH,
  AutomationComponentType.MULTI_WAY_BRANCH,
  AutomationComponentType.POSTCARD,
  AutomationComponentType.EMAIL,
  AutomationComponentType.ADD_COMP
];

export const ResizableComponentTypes: AutomationComponentType[] = [AutomationComponentType.START, AutomationComponentType.TWO_WAY_BRANCH];
export const WorkflowActionTypes: AutomationComponentType[] = [
  AutomationComponentType.ADD_TAG,
  AutomationComponentType.REMOVE_TAG,
  AutomationComponentType.CHANGE_RECORD_STATUS,
  AutomationComponentType.ROTATE_CONTACT_OWNER,
  AutomationComponentType.ARCHIVE_PROPERTY,
  AutomationComponentType.MANAGE_COMM_PREFS
];

export interface AutomationComponentTypeToSettingMap {
  [AutomationComponentType.WAIT]: AutomationWaitComponentSettings;
  [AutomationComponentType.TWO_WAY_BRANCH]: AutomationTwoWayBranchComponentSettings;
  [AutomationComponentType.POSTCARD]: AutomationPostcardComponentSettings;
  [AutomationComponentType.EMAIL]: AutomationEmailComponentSettings;
  [AutomationComponentType.START]: AutomationBaseComponentSettings;
  [AutomationComponentType.LOG]: AutomationBaseComponentSettings;
  [AutomationComponentType.GOTO]: AutomationBaseComponentSettings;
  [AutomationComponentType.END]: AutomationBaseComponentSettings;
  [AutomationComponentType.CALL_WEBHOOK]: AutomationCallWebhookComponentSettings;
  [AutomationComponentType.ADD_COMP]: AutomationBaseComponentSettings;
  [AutomationComponentType.BRANCH_TAG]: AutomationBaseComponentSettings;
  [AutomationComponentType.MULTI_WAY_BRANCH]: AutomationMultiWayBranchComponentSettings;
  [AutomationComponentType.ADD_TAG]: AutomationTagComponentSettings;
  [AutomationComponentType.REMOVE_TAG]: AutomationTagComponentSettings;
  [AutomationComponentType.CHANGE_RECORD_STATUS]: AutomationChangeRecordStatusComponentSettings;
  [AutomationComponentType.ROTATE_CONTACT_OWNER]: AutomationRotateContactOwnerComponentSettings;
  [AutomationComponentType.ARCHIVE_PROPERTY]: AutomationBaseComponentSettings;
  [AutomationComponentType.MANAGE_COMM_PREFS]: AutomationManageCommPrefsComponentSettings;
}

export type AvailableAutomationComponentTypes = keyof AutomationComponentTypeToSettingMap;
export type AutomationComponentSettings<AutomationComponentType extends AvailableAutomationComponentTypes> =
  AutomationComponentTypeToSettingMap[AutomationComponentType];

export enum AutomationComponentActionTarget {
  CONTACT = "CONTACT",
  CONTACT_1P = "CONTACT_1P",
  CONTACT_ALL_P = "CONTACT_ALL_P",
  PROPERTY = "PROPERTY"
}

export enum AutomationComponentStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE"
}

export enum AutomationComponentSetupStatus {
  TODO = "TODO",
  DONE = "DONE"
}

export const AutomationComponentSetupStatusLabel: Record<string, string> = {
  [AutomationComponentSetupStatus.TODO]: "Changes needed",
  [AutomationComponentSetupStatus.DONE]: "Ready"
};

export interface AutomationComponentDiagramMeta {
  diagProvider: string;
  meta: AutomationLadkiDiagramMeta;
}

export const AutomationComponentTypeLabel: Record<string, string> = {
  [AutomationComponentType.END]: "End",
  [AutomationComponentType.LOG]: "Log",
  [AutomationComponentType.GOTO]: "Go To",
  [AutomationComponentType.WAIT]: "Delay",
  [AutomationComponentType.TWO_WAY_BRANCH]: "Branch",
  [AutomationComponentType.POSTCARD]: "Send Postcard",
  [AutomationComponentType.EMAIL]: "Send Email",
  [AutomationComponentType.START]: "Enrollment Triggers",
  [AutomationComponentType.CALL_WEBHOOK]: "Webhook"
};

export const WorkflowComponentTypeLabel: Record<string, string> = {
  [AutomationComponentType.END]: "End",
  [AutomationComponentType.MULTI_WAY_BRANCH]: "Branch",
  [AutomationComponentType.START]: "Trigger Type",
  [AutomationComponentType.CALL_WEBHOOK]: "Webhook",
  [AutomationComponentType.ADD_COMP]: "Add an Action",
  [AutomationComponentType.ADD_TAG]: "Add Tags to Contacts",
  [AutomationComponentType.REMOVE_TAG]: "Remove Tags From Contacts",
  [AutomationComponentType.CHANGE_RECORD_STATUS]: "Change Record Status",
  [AutomationComponentType.ROTATE_CONTACT_OWNER]: "Rotate Assigned Agent",
  [AutomationComponentType.ARCHIVE_PROPERTY]: "Archive Property",
  [AutomationComponentType.MANAGE_COMM_PREFS]: "Manage Communication Preferences"
};

export interface AutomationComponent<AutomationComponentType extends AvailableAutomationComponentTypes> {
  _id: string;
  title: string;
  agencyId?: string;
  wfTaskIds?: string[];
  definitionId: string;
  type: AutomationComponentType;
  status: AutomationComponentStatus;
  setupStatus: AutomationComponentSetupStatus;
  settings?: AutomationComponentSettings<AutomationComponentType>;
  diag?: AutomationComponentDiagramMeta;
  createdAt: Date;
  updatedAt: Date;
}
