import {Component, ElementRef, forwardRef} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {tap} from "rxjs/operators";
import {SuccessDialogComponent} from "../dialogs/success-dialog/success-dialog.component";
import {AddBuyingHomeDialogComponent, HomeBuyDetails} from "./add-buying-home-dialog/add-buying-home-dialog.component";
import {DashboardModuleDirective, DashboardModuleDirectiveInputs} from "../../directives/dashboard-module.directive";
import {DashboardModuleType} from "fello-model";
import {AbstractDashboardService} from "../../services";

@Component({
  selector: "app-buying-home",
  templateUrl: "./buying-home.component.html",
  styleUrls: ["./buying-home.component.scss"],
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: DashboardModuleDirectiveInputs,
  providers: [
    {
      provide: DashboardModuleDirective,
      useExisting: forwardRef(() => BuyingHomeComponent)
    }
  ]
})
export class BuyingHomeComponent extends DashboardModuleDirective<DashboardModuleType.BUYING_PREF> {
  isLoading$ = this.dashboardService.isPropertyLoading;
  isLoading = false;
  property$ = this.dashboardService.property$;

  constructor(
    private dialog: MatDialog,
    private dashboardService: AbstractDashboardService,
    private snackBar: MatSnackBar,
    elementRef: ElementRef
  ) {
    super(elementRef);
  }

  updateHomeBuy(homeBuyDetails: HomeBuyDetails): void {
    this.isLoading = true;
    this.dashboardService
      .updateBuyingWithSelling(homeBuyDetails.buyingArea, homeBuyDetails.buyingPriceLow!, homeBuyDetails.buyingPriceHigh!)
      .subscribe(
        () => {
          this.openUpdateHomeBuySuccessful();
          this.isLoading = false;
        },
        () => {
          this.snackBar.open("Failed to update preferences", undefined, {duration: 3000});
          this.isLoading = false;
        }
      );
  }

  openUpdateHomeBuy() {
    const dialogRef = this.dialog.open(AddBuyingHomeDialogComponent, {
      panelClass: ["dialog-full-screen"]
    });

    dialogRef
      .afterClosed()
      .pipe(
        tap((homeBuyDetails: HomeBuyDetails) => {
          if (homeBuyDetails) {
            this.updateHomeBuy(homeBuyDetails);
          }
        })
      )
      .subscribe();
  }

  openUpdateHomeBuySuccessful() {
    this.dialog.open(SuccessDialogComponent, {
      data: {
        title: "Thank you for the details",
        desc: "We will get back to you soon",
        buttonText: "Continue"
      },
      panelClass: ["mobile-bottom-sheet"]
    });
  }
}
