import {Inject, Injectable, InjectionToken} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {
  AddComponentRequestParams,
  AgencyPrebuiltAutomation,
  AutomationComponent,
  AutomationComponentSettings,
  AutomationComponentType,
  AutomationDefinition,
  AutomationDefinitionCountResponse,
  AutomationDefinitionDetails,
  AutomationDefinitionSearchParams,
  AutomationSupportedEvent,
  AutomationTurnOffConfiguration,
  AutomationTurnOnConfiguration,
  AutomationTypeToActionMap,
  DeleteComponentRequestParams,
  PagedResult,
  WorkflowEventConfig,
  WorkflowEventType,
  WorkflowGroupUpdateRequest
} from "fello-model";
import {shareReplay} from "rxjs/operators";
import {cacheResponse} from "../../utils";

export const WORKFLOW_API_BASE_URL = new InjectionToken<string>("WORKFLOW_API_BASE_URL");

@Injectable({
  providedIn: "root"
})
export class WorkflowApiService {
  baseUrl: string;

  constructor(private http: HttpClient, @Inject(WORKFLOW_API_BASE_URL) private wmsBaseUrl: string) {
    this.baseUrl = `${this.wmsBaseUrl}/wms`;
  }

  createWorkflow(title: string, description?: string): Observable<{automationDefinitionId: string}> {
    return this.http.post<{
      automationDefinitionId: string;
    }>(`${this.baseUrl}/workflow/create`, {
      title,
      description
    });
  }

  searchWorkflows(params: AutomationDefinitionSearchParams): Observable<PagedResult<AutomationDefinition>> {
    return this.http.get<PagedResult<AutomationDefinition>>(`${this.baseUrl}/workflow/search`, {
      params: params as unknown as HttpParams
    });
  }

  countWorkflows(params: AutomationDefinitionSearchParams): Observable<number> {
    return this.http.get<number>(`${this.baseUrl}/workflow/count`, {
      params: params as unknown as HttpParams
    });
  }

  @cacheResponse
  getWorkflowActions(): Observable<AutomationTypeToActionMap[]> {
    return this.http.get<AutomationTypeToActionMap[]>(`${this.baseUrl}/workflow/action-list`).pipe(shareReplay(1));
  }

  getWorkflowDetails(workflowId: string): Observable<{
    automationDefinition: AutomationDefinitionDetails;
  }> {
    return this.http.get<{
      automationDefinition: AutomationDefinitionDetails;
    }>(`${this.baseUrl}/workflow/${workflowId}/details`);
  }

  getPreTurnOnMeta(workflowId: string): Observable<{meta: AutomationDefinitionCountResponse}> {
    return this.http.get<{
      meta: AutomationDefinitionCountResponse;
    }>(`${this.baseUrl}/workflow/${workflowId}/meta/turn-on/pre`);
  }

  turnOnWorkflow(
    workflowId: string,
    config: AutomationTurnOnConfiguration
  ): Observable<{
    automationDefinitionId: string;
  }> {
    return this.http.post<{
      automationDefinitionId: string;
    }>(`${this.baseUrl}/workflow/${workflowId}/toggle/active`, {config});
  }

  turnOffWorkflow(
    workflowId: string,
    config: AutomationTurnOffConfiguration
  ): Observable<{
    automationDefinitionId: string;
  }> {
    return this.http.post<{
      automationDefinitionId: string;
    }>(`${this.baseUrl}/workflow/${workflowId}/toggle/inactive`, {config});
  }

  validateWorkflowTitle(title: string): Observable<{valid: boolean}> {
    return this.http.post<{valid: boolean}>(`${this.baseUrl}/workflow/validate/title`, {title});
  }

  updateAutomationTitleAndDescription(definitionId: string, title: string, description?: string): Observable<unknown> {
    return this.http.patch(`${this.baseUrl}/workflow/${definitionId}/title-description`, {title, description});
  }

  groupUpdateWorkflowCriterias(
    definitionId: string,
    payload: WorkflowGroupUpdateRequest
  ): Observable<{automationDefinition: AutomationDefinition}> {
    return this.http.put<{
      automationDefinition: AutomationDefinition;
    }>(`${this.baseUrl}/workflow/${definitionId}/criteria/group-update`, payload);
  }

  cloneWorkflow(definitionId: string, title: string, description?: string): Observable<AutomationDefinition> {
    return this.http.post<AutomationDefinition>(`${this.baseUrl}/workflow/${definitionId}/clone`, {
      title,
      description
    });
  }

  deleteWorkflow(workflowId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/workflow/${workflowId}/`);
  }

  addComponent(
    definitionId: string,
    linkId: string,
    componentType: AutomationComponentType,
    componentSettings: AutomationComponentSettings<AutomationComponentType>,
    options?: AddComponentRequestParams
  ): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/workflow/${definitionId}/component/add`, {
      linkId,
      componentType,
      componentSettings,
      options
    });
  }

  updateWorkflowActionSettings(
    definitionId: string,
    componentId: string,
    settings: AutomationComponentSettings<AutomationComponentType>
  ): Observable<{automationComponent: AutomationComponent<AutomationComponentType>}> {
    return this.http.patch<{
      automationComponent: AutomationComponent<AutomationComponentType>;
    }>(`${this.baseUrl}/workflow/${definitionId}/component/${componentId}/settings`, {
      settings
    });
  }

  deleteComponent(definitionId: string, componentId: string, options?: DeleteComponentRequestParams) {
    return this.http.post<{
      automationComponent: AutomationComponent<AutomationComponentType>;
    }>(`${this.baseUrl}/workflow/${definitionId}/component/${componentId}/delete`, {
      options
    });
  }

  @cacheResponse
  getSupportedEventsForTrigger(): Observable<AutomationSupportedEvent[]> {
    return this.http.get<AutomationSupportedEvent[]>(`${this.baseUrl}/workflow/supported-events`);
  }

  @cacheResponse
  getConfigForWorkflowEvent(eventType: WorkflowEventType): Observable<WorkflowEventConfig> {
    return this.http.get<WorkflowEventConfig>(`${this.baseUrl}/workflow/workflow-event/${eventType}/config`);
  }

  getAllPrebuiltWorkflows(): Observable<AgencyPrebuiltAutomation[]> {
    return this.http.get<AgencyPrebuiltAutomation[]>(`${this.baseUrl}/workflow/pre-built-workflow`);
  }

  joinWaitList(preBuiltAutomationTemplateId: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/workflow/pre-built-workflow/${preBuiltAutomationTemplateId}/join-wait-list`, {});
  }

  createWorkflowFromTemplate(templateId: string, title: string, description?: string): Observable<{workflowDefinitionId: string}> {
    return this.http.post<{
      workflowDefinitionId: string;
    }>(`${this.baseUrl}/workflow/template/${templateId}/create-workflow`, {
      title,
      description
    });
  }

  getSupportedTokenFieldsForWorkflow(workflowId: string): Observable<never> {
    //ToDo: move TokenFieldMetaInfo<FTSTokenType>[] to common and replace the never type
    return this.http.get<never>(`${this.baseUrl}/workflow/${workflowId}/get-supported-token-fields`);
  }
}
