import {Injectable} from "@angular/core";
import {CookieService} from "ngx-cookie-service";
import moment from "moment";
import {MediaService} from "../media/media.service";
import {Observable, of} from "rxjs";

const TRACKING_TOKEN_COOKIE_NAME = "_lpt";

@Injectable()
export class LandingPageTrackingService {
  constructor(private mediaService: MediaService, private cookieService: CookieService) {}

  trackView(lpConfigId: string, trackingToken: string): Observable<void> {
    const overridden = this._setViewTrackingToken(lpConfigId, trackingToken);
    if (!overridden) {
      return this.mediaService.trackView(trackingToken);
    }
    return of();
  }

  private _setViewTrackingToken(lpConfigId: string, token: string): boolean {
    const cookieName = lpConfigId + TRACKING_TOKEN_COOKIE_NAME;
    const existingToken = this.cookieService.get(cookieName);
    const overridden = !!existingToken; // Check if a token already exists
    const expiryDate = moment().add(60, "seconds").toDate();
    this.cookieService.set(cookieName, token, expiryDate, "/", undefined, true, "Strict");
    return overridden;
  }
}
