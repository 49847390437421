import {MORTGAGE_RATE_MODE} from "../../dashboard-config";

export enum MortgageRateType {
  PURCHASE = "PURCHASE",
  REFINANCE = "REFINANCE"
}

export enum CreditScoreBucket {
  LOW = "Low",
  HIGH = "High",
  VERY_HIGH = "VeryHigh"
}

export enum MortgageLoanProgram {
  FIXED_30_YEAR = "Fixed30Year",
  FIXED_15_YEAR = "Fixed15Year",
  ARM_5_YEAR = "ARM5"
}

export const MortgageLoanProgramLabel: Record<MortgageLoanProgram, string> = {
  [MortgageLoanProgram.FIXED_30_YEAR]: "30-year fixed",
  [MortgageLoanProgram.FIXED_15_YEAR]: "15-year fixed",
  [MortgageLoanProgram.ARM_5_YEAR]: "5-year ARM"
};

export interface MortgageBulkDataParams {
  mortgageRateType: MortgageRateType;
  creditScoreBucket: CreditScoreBucket;
  mortgageRateMode?: MORTGAGE_RATE_MODE;
}

export interface MortgageBulkData {
  data: MortgageBulkDataItem[];
}

export interface MortgageBulkDataItem {
  loanProgram: MortgageLoanProgram;
  currentRate: number;
  periodicRates: MortgageRateDataPoint[];
}

export interface MortgageRateDataPoint {
  rate: number;
  rateObservedAt: Date;
}
