<div class="db-card page-break-inside-avoid">
  <div class="flex-box-between db-card-header flex-wrap flex-box-gap-1">
    <div class="mb-1 mr-auto">
      <p class="text-size-20 fw-600 db-card-title" *ngIf="moduleSetting.heading">{{ moduleSetting.heading }}</p>
      <p class="text-size-14 fw-400 text-color-light-2 mt-1 db-card-desc" *ngIf="moduleSetting.subHeading">
        {{ moduleSetting.subHeading }}
      </p>
    </div>
    <div class="fw-400 text-size-14 flex-box flex-box-gap-1">
      <span class="text-color-plum-5">Disclaimer</span>
      <div>
        <lib-icon class="text-color-plum-4" [libTooltip]="moduleSetting.disclaimerMessage" tooltipClass="full-width w-350-imp"
          >info
        </lib-icon>
      </div>
    </div>
  </div>
  <div class="db-card-body">
    <lib-mortgage-trend-graph [mortgageRateMode]="moduleSetting.mortgageRateMode"></lib-mortgage-trend-graph>
  </div>
  <div class="action-c flex-box-center flex-box-gap-3 mt-3 flex-sm-wrap">
    <p class="text-size-14 fw-600 mr-auto">{{ moduleSetting.ctaDescription }}</p>
    <button
      class="whitespace-nowrap button-mid"
      [ngClass]="{'button-primary': moduleSetting.ctaButton?.type === DashboardModuleButtonType.SECONDARY}"
      libDashboardTrackButtonClick
      (click)="performCTAAction(moduleSetting.ctaButton!.action)"
    >
      {{ moduleSetting.ctaButton!.label }}
    </button>
  </div>
</div>
