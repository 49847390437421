<ngx-skeleton-loader *ngIf="(isLoading$ | async) || isLoading; else loaded" count="1" [theme]="{height: '150px', width: '100%'}">
</ngx-skeleton-loader>
<ng-template #loaded>
  <ng-container *ngIf="property$ | async; let property">
    <div class="db-card" *ngIf="!property.vars?.buyingArea">
      <div class="db-card-header">
        <p class="db-card-title">Interested in buying a home?</p>
        <p class="db-card-desc">Tell us about your preferred area so that we can help you with your dream home.</p>
      </div>
      <div class="db-card-body">
        <a class="link-arrow" libDashboardTrackButtonClick [trackLabel]="'Enter Home Preferences'" (click)="openUpdateHomeBuy()">
          Enter Home Preferences
          <app-icon>right</app-icon>
        </a>
      </div>
    </div>
  </ng-container>
</ng-template>
